import { ArrowUpCircleIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { DashboardLayout } from "../../components/dashboard/layouts/DashboardLayout";
import { classNames } from "../../utils/styles";
import {
  ArrowDownCircleIcon,
  ArrowPathIcon,
  ArrowTopRightOnSquareIcon,
  BanknotesIcon,
  CheckIcon,
  CurrencyEuroIcon,
  TicketIcon,
} from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { DashboardOrder, DashboardTicket } from "../../models/Dashboard";
import { Table } from "../../components/Table";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../store/store";
import { dashboardActions } from "../../store/dashboard";
import { SkeletonLoading } from "../../components/SkeletonLoading";
import { Statistics } from "../../components/Statistics";
import { StatisticsV2 } from "../../components/StatisticsV2";
import { ViewEventButton } from "../../components/dashboard/nav/ViewEventButton";

export const Home = () => {
  const dispatch = useDispatch();
  const contentLoading = useSelector(
    (state: State) => state.dashboard.contentLoading
  );
  const ticketSalesLoading = useSelector(
    (state: State) => state.dashboard.ticketSalesLoading
  );
  const recentOrdersLoading = useSelector(
    (state: State) => state.dashboard.recentOrdersLoading
  );
  const ticketSales = useSelector(
    (state: State) => state.dashboard.ticketSales
  );
  const recentOrders = useSelector(
    (state: State) => state.dashboard.recentOrders
  );
  const statistics = useSelector((state: State) => state.dashboard.statistics);
  const [parsedStatistics, setParsedStatistics] = useState([]);

  useEffect(() => {
    dispatch(dashboardActions.SetContentLoading({ contentLoading: true }));
    dispatch(dashboardActions.GetStatistics());
    dispatch(dashboardActions.GetTicketSales());
    dispatch(dashboardActions.GetRecentOrders());
  }, []);

  return (
    <DashboardLayout
      requiresSuperuser={true}
      requiredPermissions={[]}
      pageTitle="Home"
      rightControls={<ViewEventButton />}
    >
      <div className="relative isolate overflow-hidden">
        {/* <Statistics /> */}
        <StatisticsV2 />

        <div
          className="absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
          aria-hidden="true"
        >
          <div
            className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#a855f7] to-[#6b21a8]"
            style={{
              clipPath:
                "polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)",
            }}
          />
        </div>
      </div>

      <div>
        <div className="py-6 dark:border-white/5 border-b dark:border-y border-b-gray-900/10">
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="dark:text-white mx-auto max-w-2xl text-base font-semibold leading-6 text-gray-900 lg:mx-0 lg:max-w-none">
              Sales by ticket type
            </h2>
          </div>
        </div>

        <div>
          {ticketSalesLoading ? (
            <div className="p-8">
              <SkeletonLoading />
            </div>
          ) : (
            <>
              {ticketSales?.length > 0 ? (
                <Table
                  headers={["Name", "Price", "Sold"]}
                  records={ticketSales}
                  rowRenderer={(ticket: DashboardTicket) => (
                    <>
                      <td className="w-full max-w-0 whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900">
                        <div className="items-center lg:pl-2">
                          <div className="truncate dark:text-white">
                            <span>{ticket.name}</span>
                          </div>
                          <div className="flex items-center mt-0.5">
                            {ticket.isTicketOnSale ? (
                              <>
                                <div className="mr-2 relative">
                                  <div className="h-3 w-3 text-green-400 bg-green-500/10 rounded-full animate-ping"></div>
                                  <div className="absolute h-2 w-2 rounded-full bg-green-500 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                                </div>
                                <span className="font-normal text-gray-500">
                                  On sale until{" "}
                                  <>
                                    {format(
                                      new Date(ticket.onSaleTo),
                                      "dd LLL yyyy"
                                    )}
                                  </>
                                </span>
                              </>
                            ) : (
                              <>
                                <div className="mr-2 relative">
                                  <div className="h-3 w-3 text-green-400 bg-red-500/10 rounded-full"></div>
                                  <div className="absolute h-2 w-2 rounded-full bg-red-500 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                                </div>
                                <span className="font-normal text-gray-500">
                                  Not on sale
                                </span>
                              </>
                            )}
                            <span className="font-normal text-gray-500 ml-1 pr-1">
                              {/* • &nbsp;{ticket.onSaleFrom.toDateString()} */}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-3 text-sm font-medium text-gray-500">
                        <div className="flex items-center space-x-2">
                          <div className="flex flex-shrink-0 -space-x-1">
                            &euro;{ticket.price}
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-6 py-3 text-right text-sm text-gray-500 md:table-cell">
                        {ticket.quantitySold}/{ticket.quantityAvailable}
                      </td>
                    </>
                  )}
                />
              ) : (
                <div className="border-b border-gray-200 dark:border-white/5 pb-14 pt-6">
                  <div className="text-center">
                    <TicketIcon className="mx-auto h-12 w-12 text-gray-400" />
                    <h3 className="mt-2 text-sm font-semibold text-gray-900">
                      No tickets for this event yet
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      When tickets are setup and sold, their sales will appear
                      here
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* Recent Orders */}
      <div>
        <div className="py-6 dark:border-white/5 border-b border-b-gray-900/10">
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="dark:text-white mx-auto max-w-2xl text-base font-semibold leading-6 text-gray-900 lg:mx-0 lg:max-w-none">
              Recent orders
            </h2>
          </div>
        </div>

        {recentOrdersLoading ? (
          <div className="p-8">
            <SkeletonLoading />
          </div>
        ) : (
          <>
            {recentOrders?.length > 0 ? (
              <div className="overflow-hidden border-t border-gray-100 dark:border-t-0">
                <div className="mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                    <table className="w-full text-left">
                      <tbody>
                        {recentOrders.map((order) => (
                          <Fragment
                            key={format(new Date(order.date), "dd LLL yyyy")}
                          >
                            <tr className="text-sm leading-6 text-gray-900">
                              <th
                                scope="colgroup"
                                colSpan={3}
                                className="relative isolate py-2 font-semibold dark:text-white"
                              >
                                <time
                                  dateTime={format(
                                    new Date(order.date),
                                    "dd LLL yyyy"
                                  )}
                                >
                                  {format(new Date(order.date), "dd LLL yyyy")}
                                </time>
                                <div className="absolute inset-y-0 right-full -z-10 w-screen border-b dark:border-white/5 border-gray-200 bg-gray-50 dark:bg-dark-secondary" />
                                <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b dark:border-white/5 border-gray-200 bg-gray-50 dark:bg-dark-secondary" />
                              </th>
                            </tr>
                            <>
                              {order.orders && (
                                <>
                                  {order.orders.map((order: DashboardOrder) => (
                                    <tr key={order.id}>
                                      <td className=" py-5 pr-6 sm:table-cell">
                                        <div className="text-sm leading-6 text-gray-900 dark:text-white">
                                          {order.firstName} {order.lastName}
                                        </div>
                                        <div className="mt-1 text-xs leading-5 text-gray-500 flex items-center dark:text-gray-400">
                                          {order.monthlyTotal ? (
                                            <ArrowPathIcon className="w-3.5 h-3.5 mr-1.5" />
                                          ) : (
                                            <CheckIcon className="w-3.5 h-3.5 mr-1.5" />
                                          )}
                                          {order.monthlyTotal
                                            ? "Payment Plan"
                                            : "Once Off"}
                                        </div>
                                      </td>
                                      <td className="relative py-5 pr-6">
                                        <div className="flex gap-x-6">
                                          <div className="flex-auto">
                                            <div className="flex items-start gap-x-3">
                                              <div className="text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                                &euro;{order.total}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100 dark:bg-white/5" />
                                        <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100 dark:bg-white/5" />
                                      </td>
                                      <td className="py-5 text-right">
                                        <div className="flex justify-end">
                                          <Link
                                            to={`/dashboard/orders/${order.id}`}
                                            className="text-sm font-medium leading-6 text-indigo-600 hover:text-indigo-500"
                                          >
                                            View order
                                          </Link>
                                        </div>
                                        <div className="mt-1 text-xs leading-5 text-gray-500 dark:text-gray-400">
                                          Order{" "}
                                          <span className="text-gray-900 dark:text-gray-400">
                                            #{order.uuid}
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </>
                              )}
                            </>
                          </Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <div className="border-b border-gray-200 dark:border-white/5 pb-14 pt-6">
                <div className="text-center">
                  <BanknotesIcon className="mx-auto h-12 w-12 text-gray-400" />
                  <h3 className="mt-2 text-sm font-semibold text-gray-900">
                    No orders for this event
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    When tickets are bought, the most recent orders will be
                    shown here
                  </p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </DashboardLayout>
  );
};
