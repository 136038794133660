import {
  AdjustmentsHorizontalIcon,
  ArrowRightOnRectangleIcon,
  UserIcon,
} from "@heroicons/react/20/solid";
import { signOut } from "../../../../pages/protected-route";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { State } from "../../../../store/store";
import { ButtonSpinner } from "../../../ButtonSpinner";

export const AccountMenuContent = () => {
  const navigate = useNavigate();
  const organisation = useSelector(
    (state: State) => state.dashboard.organisation
  );

  return (
    <div className="">
      <div className="border-b dark:border-white/5">
        <p className="truncate px-3.5 py-3" role="none">
          <span className="block text-xs text-gray-400" role="none">
            Organisation
          </span>
          <span
            className="mt-0.5 font-semibold text-gray-900 dark:text-white "
            role="none"
          >
            {organisation ? (
              organisation.name
            ) : (
              <div className="mt-1">
                <ButtonSpinner />
              </div>
            )}
          </span>
        </p>
      </div>
      <div
        onClick={() => navigate("/dashboard/settings/organisation")}
        className="flex items-center px-3.5 pt-3 pb-3 text-gray-400 text-sm hover:text-gray-500 cursor-pointer"
      >
        <AdjustmentsHorizontalIcon className="h-4 w-4 mr-1.5" />
        Organisation Settings
      </div>
      <div
        onClick={() => navigate("/dashboard/settings/user")}
        className="flex items-center px-3.5 pb-3 text-gray-400 text-sm hover:text-gray-500 cursor-pointer"
      >
        <UserIcon className="h-4 w-4 mr-1.5" />
        User Settings
      </div>
      <div
        onClick={() => signOut()}
        className="cursor-pointer flex px-3.5 py-3 border-t dark:border-white/5 text-gray-400 text-sm items-center hover:text-gray-500"
      >
        <ArrowRightOnRectangleIcon className="h-4 w-4 mr-1.5" />
        Sign Out
      </div>
    </div>
  );
};
