import { Field, getIn, useFormikContext } from "formik";
import { useEffect, useState } from "react";

interface Props {
  type: string;
  name: string;
  required: boolean;
  isMoney?: boolean;
  trailingAddon?: string;
}

export const Input = ({
  type,
  name,
  required,
  isMoney,
  trailingAddon,
}: Props) => {
  const { errors } = useFormikContext<any>();
  const [error, setError] = useState<any>("");

  useEffect(() => {
    setError(getIn(errors, name) || "");
  }, [errors]);

  return (
    <>
      {trailingAddon ? (
        <div className="relative rounded-md shadow-sm w-40">
          <Field
            type={type}
            name={name}
            id={name}
            required={required}
            className={`${
              error ? "ring-red-500" : "dark:ring-white/5 ring-gray-300"
            } block dark:bg-dark-secondary dark:text-gray-400 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pr-11`}
          />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span id="price-currency" className="text-gray-500 sm:text-sm">
              {trailingAddon}
            </span>
          </div>
        </div>
      ) : (
        <>
          {isMoney ? (
            <div className="relative rounded-md shadow-sm w-40">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm">&euro;</span>
              </div>
              <Field
                type={type}
                name={name}
                id={name}
                required={required}
                className={`${
                  error ? "ring-red-500" : "dark:ring-white/5 ring-gray-300"
                } block dark:bg-dark-secondary dark:text-gray-400 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pr-11 pl-6`}
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span id="price-currency" className="text-gray-500 sm:text-sm">
                  EUR
                </span>
              </div>
            </div>
          ) : (
            <Field
              type={type}
              name={name}
              id={name}
              required={required}
              className={`${
                error ? "ring-red-500" : "dark:ring-white/5 ring-gray-300"
              } block dark:bg-dark-secondary dark:text-gray-400 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
            />
          )}
        </>
      )}
      {error && <span className="text-red-500 text-xs">{error}</span>}
    </>
  );
};
