import { Menu, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { classNames } from "../../../utils/styles";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "../../../store/dashboard";
import { State } from "../../../store/store";
import { format } from "date-fns";
import { Event } from "../../../models/Dashboard";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { EVENT_KEY } from "../../../pages/protected-route";
import { PlusIcon } from "@heroicons/react/20/solid";

export const EventSelector = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const events: Array<Event> = useSelector(
    (state: State) => state.dashboard.events
  );

  useEffect(() => {
    dispatch(dashboardActions.GetEvents());
  }, []);

  useEffect(() => {
    if (events && events.length > 0 && !localStorage.getItem("event")) {
      setActiveEvent(events[0]);
    }
  }, [events]);

  // Set event and set axios header
  const setActiveEvent = (activeEvent: Event) => {
    localStorage.setItem(EVENT_KEY, JSON.stringify(activeEvent));
    window.location.reload();
  };

  // Get Event from Local Storage
  const event = JSON.parse(localStorage.getItem("event") || "{}");

  return (
    <Menu as="div" className="relative inline-block px-3 text-left">
      <div>
        <Menu.Button className="dark:text-white dark:bg-transparent group w-full rounded-md bg-gray-100 bg-light-secondary px-3.5 py-2 text-left text-sm font-medium text-gray-700 hover:bg-gray-200 dark:hover:bg-dark-secondary/60 focus:outline-none focus:ring-2 focus:ring-purple-500">
          <span className="flex w-full items-center justify-between">
            <span className="flex min-w-0 items-center justify-between space-x-3">
              <span className="flex min-w-0 flex-1 flex-col">
                <div className="flex items-center">
                  <div className="text-center mr-2">
                    {Object.keys(event).length === 0 ? (
                      <div className="bg-light-primary rounded-md dark:bg-gray-700 animate-pulse w-6 h-8"></div>
                    ) : (
                      <>
                        {" "}
                        <div
                          className="text-xs text-orange-500"
                          style={{ fontSize: 12 }}
                        >
                          {format(
                            new Date(event.startDate),
                            "MMM"
                          ).toUpperCase()}
                        </div>
                        <div
                          className="font-semibold -mt-0.5"
                          style={{ fontSize: 16 }}
                        >
                          {format(
                            new Date(event.startDate),
                            "dd"
                          ).toUpperCase()}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="ml-1">
                    {Object.keys(event).length === 0 ? (
                      <div className="bg-gray-300 rounded-md dark:bg-gray-700 animate-pulse w-32 h-3 mb-1"></div>
                    ) : (
                      <div className="text-sm font-medium text-gray-900 dark:text-white line-clamp-1">
                        {event.name}
                      </div>
                    )}
                    {!event ? (
                      <div className="bg-gray-300 rounded-md dark:bg-gray-700 animate-pulse w-24 h-3 mb-1"></div>
                    ) : (
                      <div className="text-xs font-light text-gray-600 dark:text-gray-400 line-clamp-1">
                        {event.location}
                      </div>
                    )}
                  </div>
                </div>
              </span>
            </span>
            <ChevronUpDownIcon
              className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500 dark:text-gray-400 -mr-1"
              aria-hidden="true"
            />
          </span>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="dark:bg-dark-secondary absolute left-0 right-0 z-10 mx-3 mt-1 origin-top divide-y divide-gray-200 dark:border-white/5 border border-transparent dark:divide-white/5 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {events && events.length > 0 && (
              <>
                {events.map((singleEvent: Event) => {
                  if (singleEvent.id !== event.id) {
                    return (
                      <Menu.Item key={event.id}>
                        {({ active }) => (
                          <div>
                            <div
                              onClick={() => setActiveEvent(singleEvent)}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900 dark:text-white"
                                  : "text-gray-700 dark:text-gray-400",
                                "block px-4 py-2 text-sm flex items-center px-3 py-2 dark:hover:bg-gray-700 cursor-pointer"
                              )}
                            >
                              <div className="text-center mr-2">
                                <div
                                  className="text-xs text-orange-500"
                                  style={{ fontSize: 12 }}
                                >
                                  {format(
                                    new Date(singleEvent.startDate),
                                    "MMM"
                                  ).toUpperCase()}
                                </div>
                                <div
                                  className="font-semibold -mt-0.5 dark:text-white"
                                  style={{ fontSize: 16 }}
                                >
                                  {format(
                                    new Date(singleEvent.startDate),
                                    "dd"
                                  ).toUpperCase()}
                                </div>
                              </div>
                              <div className="ml-1 line-clamp-2">
                                <div className="truncate text-sm font-medium text-gray-900 dark:text-white">
                                  <p className="truncate">{singleEvent.name}</p>
                                </div>
                                <div className="truncate text-xs font-light text-gray-600 dark:text-gray-400">
                                  {singleEvent.location}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Menu.Item>
                    );
                  }
                })}
              </>
            )}
            <Menu.Item key="Create Event">
              {({ active }) => (
                <div
                  onClick={() => {
                    dispatch(
                      dashboardActions.SetCreateEventSuccess({
                        createEventSuccess: false,
                      })
                    );
                    dispatch(
                      dashboardActions.SetCreateEventModalOpen({
                        createEventModalOpen: true,
                      })
                    );
                  }}
                  className={classNames(
                    active
                      ? "bg-gray-100 text-gray-900 dark:text-white"
                      : "text-gray-700 dark:text-gray-400",
                    "block px-3 py-2 text-sm flex items-center px-3 py-2 dark:hover:bg-gray-700 cursor-pointer"
                  )}
                >
                  <PlusIcon className="h-6 w-6" />
                  <div className="ml-2.5 text-md font-semibold">
                    Create Event
                  </div>
                </div>
              )}
            </Menu.Item>
          </div>
          {/* <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active
                      ? "bg-gray-100 text-gray-900 dark:text-white"
                      : "text-gray-700 dark:text-gray-400",
                    "block px-4 py-2 text-sm dark:hover:bg-gray-700 cursor-pointer"
                  )}
                >
                  Create Event
                </a>
              )}
            </Menu.Item>
          </div> */}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
