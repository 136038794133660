import { useDispatch, useSelector } from "react-redux";
import { State } from "../store/store";
import { useEffect, useState } from "react";
import { dashboardActions } from "../store/dashboard";
import { formatNumberToMoney } from "../utils/cart";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Statistics } from "../models/Dashboard";
import { getStatistics } from "../dashboardQueries";
import { ACCESS_TOKEN_KEY } from "../pages/protected-route";
import { SalesLast7Days } from "./dashboard/charts/SalesLast7Days";

export const StatisticsV2 = () => {
  const [statistics, setStatistics] = useState<Statistics | null>(null);
  const dispatch = useDispatch();
  const [intervalId, setIntervalId] = useState<number>();
  const statisticsIntervalId = useSelector(
    (state: State) => state.dashboard.statisticsIntervalId
  );

  const fetchStatistics = (eventId: string) => {
    getStatistics(eventId).then((response) => {
      // Only set statistics if we have a stat figure like grossTotal
      if (response.data) {
        setStatistics(response.data);
      }
    });
  };

  const setupStatistics = () => {
    const eventId = JSON.parse(localStorage.getItem("event") || "{}").id;
    if (eventId) {
      fetchStatistics(eventId);
      // Clear interval if it exists to prevent multiple intervals
      // if (statisticsIntervalId) {
      //   clearInterval(statisticsIntervalId);
      // }
      // const newIntervalId = setInterval(() => {
      //   fetchStatistics(eventId);
      // }, 10000);
      // setIntervalId(parseInt(newIntervalId.toString()));
      // dispatch(
      //   dashboardActions.SetStatisticsIntervalId({
      //     statisticsIntervalId: parseInt(intervalId.toString()),
      //   })
      // );
    }
  };

  useEffect(() => {
    setupStatistics();

    // Also run on localstorage change
    window.addEventListener("storage", setupStatistics);

    return () => {
      window.removeEventListener("storage", setupStatistics);
    };
  }, []);

  const Loading = () => {
    return (
      <div>
        <div className="h-5 bg-gray-300 rounded-md dark:bg-gray-600 w-24 mb-2.5 mt-1 animate-pulse"></div>
      </div>
    );
  };

  const SmallLoading = () => {
    return (
      <div>
        <div className="ml-1 h-2 bg-gray-300 rounded-sm dark:bg-gray-600 w-10 mb-2.5 mt-1 mr-2"></div>
      </div>
    );
  };

  return (
    <div className="border-b border-b-gray-900/10">
      <div className="w-full flex justify-end py-2 border-b-gray-900/5 border-b dark:border-b-white/5 pr-2">
        <p className="text-xs dark:text-gray-400 text-gray-900 flex mr-2">
          <InformationCircleIcon className="h-4 w-4 mr-1" /> Realtime Statistics
          update every 10 seconds
        </p>
      </div>
      <dl className="mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:px-0">
        <div className="border-r border-b flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8 dark:border-white/5">
          <dt className="text-sm font-medium leading-6 text-gray-500 truncate">
            Total Ticket Sales
          </dt>
          <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900 dark:text-white">
            {!statistics ? (
              <Loading />
            ) : (
              statistics?.ticketsSold && statistics.ticketsSold.toLocaleString()
            )}
          </dd>
          <dd className="text-gray-400 font-semithin text-xs flex">
            {statistics?.ticketsIssued.toLocaleString()} Issued -{" "}
            {statistics?.ticketsPending.toLocaleString()} Pending
          </dd>
        </div>

        <div className="border-r border-b flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8 dark:border-white/5">
          <dt className="text-sm font-medium leading-6 text-gray-500 truncate">
            Daily Sales
          </dt>
          <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900 dark:text-white">
            {!statistics ? (
              <Loading />
            ) : (
              statistics?.salesCounts.daily.tickets +
              statistics?.salesCounts.daily.addons
            )}
          </dd>
          <dd className="text-gray-400 font-semithin text-xs flex">
            {!statistics ? (
              <SmallLoading />
            ) : (
              statistics?.salesCounts.daily.tickets
            )}{" "}
            Tickets -{" "}
            {!statistics ? (
              <SmallLoading />
            ) : (
              statistics?.salesCounts.daily.addons
            )}{" "}
            Add-ons
          </dd>
        </div>

        <div className="border-b flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8 dark:border-white/5">
          <dt className="text-sm font-medium leading-6 text-gray-500 truncate">
            Weekly Sales
          </dt>
          <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900 dark:text-white">
            {!statistics ? (
              <Loading />
            ) : (
              statistics?.salesCounts.weekly.tickets +
              statistics?.salesCounts.weekly.addons
            )}
          </dd>
          <dd className="text-gray-400 font-semithin text-xs flex">
            {!statistics ? (
              <SmallLoading />
            ) : (
              statistics?.salesCounts.weekly.tickets
            )}{" "}
            Tickets -{" "}
            {!statistics ? (
              <SmallLoading />
            ) : (
              statistics?.salesCounts.weekly.addons
            )}{" "}
            Add-ons
          </dd>
        </div>

        <div className="border-r flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8 dark:border-white/5">
          <dt className="text-sm font-medium leading-6 text-gray-500 truncate">
            Total Sales
          </dt>
          <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900 dark:text-white">
            {!statistics ? (
              <Loading />
            ) : (
              statistics?.netTotal &&
              formatNumberToMoney(statistics?.netTotal + statistics.addonSales)
            )}
          </dd>
          <dd className="text-gray-400 font-semithin text-xs flex">
            {!statistics ? (
              <SmallLoading />
            ) : (
              formatNumberToMoney(statistics?.netTotal)
            )}{" "}
            Tickets -{" "}
            {!statistics ? (
              <SmallLoading />
            ) : (
              formatNumberToMoney(statistics?.addonSales)
            )}{" "}
            Add-ons
          </dd>
        </div>

        <div className="border-r flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8 dark:border-white/5">
          <dt className="text-sm font-medium leading-6 text-gray-500 truncate">
            Sales Outstanding
          </dt>
          <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900 dark:text-white">
            {!statistics ? (
              <Loading />
            ) : (
              formatNumberToMoney(statistics?.outstandingPlanSales)
            )}
          </dd>
          <dd className="text-gray-400 font-semithin text-xs flex">&nbsp;</dd>
        </div>

        <div className="flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8 dark:border-white/5">
          <dt className="text-sm font-medium leading-6 text-gray-500 truncate">
            Carbon Footprint
          </dt>
          <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900 dark:text-white">
            {!statistics ? (
              <Loading />
            ) : (
              formatNumberToMoney(
                statistics?.carbonFootprintContributions +
                  statistics.paymentPlanCarbonFootprintContributions
              )
            )}
          </dd>
          <dd className="text-gray-400 font-semithin text-xs flex">&nbsp;</dd>
        </div>

        <div className="border-r flex items-baseline flex-wrap gap-x-4 border-t border-gray-900/5 px-4 py-20 sm:px-6 xl:px-8 dark:border-white/5">
          <dt className="text-sm font-medium leading-6 text-gray-500 truncate">
            Addon Sales
          </dt>
          <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900 dark:text-white">
            {!statistics ? (
              <Loading />
            ) : statistics.addonSales && statistics.addonSales > 0 ? (
              formatNumberToMoney(statistics?.addonSales)
            ) : (
              0
            )}
          </dd>
          <dd className="text-gray-400 font-semithin text-xs flex">
            Sold {!statistics ? <SmallLoading /> : statistics.addonCount} addons
          </dd>
          <dd className="text-gray-400 font-semithin text-xs flex">&nbsp;</dd>
        </div>

        <div className="border-r flex items-baseline flex-wrap gap-x-4 border-t border-gray-900/5 px-4 py-20 sm:px-6 xl:px-8 dark:border-white/5">
          <dt className="text-sm font-medium leading-6 text-gray-500 truncate">
            Attendees Checked-in
          </dt>
          <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900 dark:text-white">
            {!statistics ? (
              <Loading />
            ) : (
              statistics?.attendeesCheckedIn && statistics?.attendeesCheckedIn
            )}
          </dd>
          <dd className="text-gray-400 font-semithin text-xs flex">&nbsp;</dd>
        </div>

        <div className="border-r flex items-baseline flex-wrap justify-between gap-y-2 border-t border-gray-900/5 py-4 px-4 dark:border-white/5 w-full relative pb-10">
          <dt className="text-sm font-medium leading-6 text-gray-500 truncate mb-1">
            Sales for the last 7 days
          </dt>
          {statistics?.totalRange && (
            <SalesLast7Days totalRange={statistics?.totalRange} />
          )}
          <dd className="text-gray-400 font-semithin text-xs flex mt-10 absolute bottom-0 right-0 mb-2 mr-4">
            <InformationCircleIcon className="h-4 w-4 mr-1" />
            Includes payment plan sales received
          </dd>
        </div>
      </dl>
    </div>
  );
};
