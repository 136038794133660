import { useEffect, useState } from "react";
import { ACCESS_TOKEN_KEY, signOut } from "../pages/protected-route";
import { decodeToken } from "react-jwt";

interface DecodedToken {
  group_permissions: string[];
  user_permissions: string[];
  is_superuser: boolean;
}

export const useUserPermissions = (): [string[], boolean] => {
  const [permissions, setPermissions] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchPermissions = () => {
      // Get the access token
      const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

      // If the token isn't there, logout
      if (!accessToken) {
        signOut();
        setLoading(false);
        return;
      }

      // Decode the token
      const decodedToken = decodeToken<DecodedToken>(accessToken);

      // If the token isn't decoded properly, logout
      if (!decodedToken) {
        signOut();
        setLoading(false);
        return;
      }

      // Get group_permissions and user_permissions from the token
      const { group_permissions, user_permissions, is_superuser } =
        decodedToken;

      if (group_permissions === undefined || user_permissions === undefined) {
        signOut();
      }

      // Combine and remove duplicates
      const allPermissions = Array.from(
        new Set([...group_permissions, ...user_permissions])
      );

      // Add "superuser" to the permissions if is_superuser is true
      if (is_superuser) {
        allPermissions.push("superuser");
      }

      setPermissions(allPermissions);
      setLoading(false);
    };

    fetchPermissions();
  }, []);

  return [permissions, loading];
};
