import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fork, takeEvery, put } from "@redux-saga/core/effects";
import { CartItem, PromoCode, PromoCodeType } from "../models/Cart";
import { Order, OrderResponse } from "../models/Order";
import { createOrder, createPaymentPlan, getPromoCode } from "../queries";
import { AxiosError, AxiosResponse } from "axios";
import { Ticket } from "../models/Tickets";
import { calculateDiscount } from "../utils/cart";
import {
  DashboardOrder,
  DashboardOrderResponse,
  DashboardTicket,
  EmailMessage,
  Event,
  Organisation,
  PaginatedResponse,
  RecentOrder,
  SignInRequest,
  SignInResponse,
  Statistics,
  UpdatePasswordRequest,
  User,
} from "../models/Dashboard";
import {
  createEvent,
  downloadOrderQRCodes,
  getAllTickets,
  getEvents,
  getMe,
  getOrder,
  getOrderEmails,
  getOrders,
  getOrganisation,
  getRecentOrders,
  getStatistics,
  getTickets,
  getTicketSales,
  resendOrderConfirmation,
  signIn,
  updateMe,
  updatePassword,
} from "../dashboardQueries";
import { createReducers } from "../crud/store";
import toast from "react-hot-toast";
import { history } from "..";
import { decodeToken } from "react-jwt";
import {
  ACCESS_TOKEN_KEY,
  EMAIL_KEY,
  REFRESH_TOKEN_KEY,
  USER_ID_KEY,
} from "../pages/protected-route";
import JSZip from "jszip";
const FileSaver = require("file-saver");

export interface DashboardState {
  sidebarOpen: boolean;
  contentLoading: boolean;
  tickets?: PaginatedResponse<DashboardTicket>;
  allTickets: Array<DashboardTicket>;
  ticketSales: Array<DashboardTicket>;
  ticketSalesLoading: boolean;
  recentOrders: Array<RecentOrder>;
  recentOrdersLoading: boolean;
  orders?: PaginatedResponse<DashboardOrder> | undefined;
  order?: DashboardOrder | undefined;
  statistics?: Statistics | undefined;
  events: Array<Event>;
  isSigningIn: boolean;
  hasTokenRefreshed: boolean;
  statisticsIntervalId: number;
  createEventModalOpen: boolean;
  createEventSuccess: boolean;
  organisation?: Organisation;
  me: User | undefined;
  orderEmails: Array<EmailMessage> | undefined;
}

const initialState = {
  sidebarOpen: false,
  contentLoading: false,
  allTickets: [],
  sideBarOpen: false,
  ticketSales: [],
  recentOrders: [],
  orders: undefined,
  order: undefined,
  statistics: undefined,
  events: [],
  hasTokenRefreshed: false,
  statisticsIntervalId: 0,
  organisation: undefined,
  ticketSalesLoading: false,
  recentOrdersLoading: false,
  isSigningIn: false,
  createEventModalOpen: false,
  createEventSuccess: false,
  me: undefined,
  orderEmails: undefined,
} as DashboardState;

export const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState: initialState,
  reducers: {
    SetSideBarOpen(
      state: DashboardState,
      action: PayloadAction<{ sidebarOpen: boolean }>
    ) {
      state.sidebarOpen = action.payload.sidebarOpen;
    },

    SetContentLoading(
      state: DashboardState,
      action: PayloadAction<{ contentLoading: boolean }>
    ) {
      state.contentLoading = action.payload.contentLoading;
    },

    GetTickets(
      state: DashboardState,
      action: PayloadAction<{ page: number }>
    ) {},

    GetAllTickets(state: DashboardState, action: PayloadAction) {},

    SetTickets(
      state: DashboardState,
      action: PayloadAction<{ tickets: PaginatedResponse<DashboardTicket> }>
    ) {
      state.tickets = action.payload.tickets;
    },

    SetAllTickets(
      state: DashboardState,
      action: PayloadAction<{ tickets: Array<DashboardTicket> }>
    ) {
      state.allTickets = action.payload.tickets;
    },

    GetTicketSales(state: DashboardState, action: PayloadAction) {},

    SetTicketSales(
      state: DashboardState,
      action: PayloadAction<{ tickets: Array<DashboardTicket> }>
    ) {
      state.ticketSales = action.payload.tickets;
    },

    SetTicketSalesLoading(
      state: DashboardState,
      action: PayloadAction<{ ticketSalesLoading: boolean }>
    ) {
      state.ticketSalesLoading = action.payload.ticketSalesLoading;
    },

    GetRecentOrders(state: DashboardState, action: PayloadAction) {},

    SetRecentOrders(
      state: DashboardState,
      action: PayloadAction<{ orders: Array<RecentOrder> }>
    ) {
      state.recentOrders = action.payload.orders;
    },

    SetRecentOrdersLoading(
      state: DashboardState,
      action: PayloadAction<{ recentOrdersLoading: boolean }>
    ) {
      state.recentOrdersLoading = action.payload.recentOrdersLoading;
    },

    GetOrders(
      state: DashboardState,
      action: PayloadAction<{ page: number }>
    ) {},

    SetOrders(
      state: DashboardState,
      action: PayloadAction<{ orders: PaginatedResponse<DashboardOrder> }>
    ) {
      state.orders = action.payload.orders;
    },

    GetOrder(
      state: DashboardState,
      action: PayloadAction<{ orderId: string }>
    ) {},

    SetOrder(
      state: DashboardState,
      action: PayloadAction<{ order: DashboardOrder }>
    ) {
      state.order = action.payload.order;
    },

    GetStatistics(state: DashboardState, action: PayloadAction) {},

    SetStatistics(
      state: DashboardState,
      action: PayloadAction<{ statistics: Statistics }>
    ) {
      state.statistics = action.payload.statistics;
    },

    SignIn(
      state: DashboardState,
      action: PayloadAction<{ signInRequest: SignInRequest }>
    ) {},

    SetIsSigningIn(
      state: DashboardState,
      action: PayloadAction<{ isSigningIn: boolean }>
    ) {
      state.isSigningIn = action.payload.isSigningIn;
    },

    GetEvents(state: DashboardState, action: PayloadAction) {},

    SetEvents(
      state: DashboardState,
      action: PayloadAction<{ events: Array<Event> }>
    ) {
      state.events = action.payload.events;
    },

    ResendOrderConfirmtion(
      state: DashboardState,
      action: PayloadAction<{ orderUUID: string }>
    ) {},

    DownloadOrderQRCodes(
      state: DashboardState,
      action: PayloadAction<{ orderUUID: string }>
    ) {},

    SetHasTokenRefereshed(
      state: DashboardState,
      action: PayloadAction<{ hasTokenRefreshed: boolean }>
    ) {
      state.hasTokenRefreshed = action.payload.hasTokenRefreshed;
    },

    SetStatisticsIntervalId(
      state: DashboardState,
      action: PayloadAction<{ statisticsIntervalId: number }>
    ) {
      state.statisticsIntervalId = action.payload.statisticsIntervalId;
    },

    SetCreateEventModalOpen(
      state: DashboardState,
      action: PayloadAction<{ createEventModalOpen: boolean }>
    ) {
      state.createEventModalOpen = action.payload.createEventModalOpen;
    },

    CreateEvent(
      state: DashboardState,
      action: PayloadAction<{ event: Event }>
    ) {},

    SetCreateEventSuccess(
      state: DashboardState,
      action: PayloadAction<{ createEventSuccess: boolean }>
    ) {
      state.createEventSuccess = action.payload.createEventSuccess;
    },

    GetOrganisation(state: DashboardState, action: PayloadAction) {},

    SetOrganisation(
      state: DashboardState,
      action: PayloadAction<{ organisation: Organisation }>
    ) {
      state.organisation = action.payload.organisation;
    },

    // For now, it's one Org per deployable. In the future, we can add multi-tenancy
    EnableOrganisationView(state: DashboardState, action: PayloadAction) {},

    GetMe(state: DashboardState, action: PayloadAction) {},

    SetMe(state: DashboardState, action: PayloadAction<{ me: User }>) {
      state.me = action.payload.me;
    },

    UpdateMe(state: DashboardState, action: PayloadAction<{ me: User }>) {},

    UpdatePassword(
      state: DashboardState,
      action: PayloadAction<{ updatePasswordRequest: UpdatePasswordRequest }>
    ) {},

    GetOrderEmails(
      state: DashboardState,
      action: PayloadAction<{ recipient: string }>
    ) {},

    SetOrderEmails(
      state: DashboardState,
      action: PayloadAction<{ orderEmails: Array<EmailMessage> }>
    ) {
      state.orderEmails = action.payload.orderEmails;
    },
  },
});

export const dashboardActions = {
  ...dashboardSlice.actions,
};

export const dashboardSelector = (state: any) =>
  state[dashboardSlice.name] as DashboardState;

function* dashboardSideEffects() {
  // Get all Tickets for Dashboard
  yield takeEvery(dashboardActions.GetTickets, function* (action) {
    yield put(dashboardActions.SetContentLoading({ contentLoading: true }));
    const ticketResponse: AxiosResponse<PaginatedResponse<DashboardTicket>> =
      yield getTickets(action.payload.page);
    yield put(dashboardActions.SetTickets({ tickets: ticketResponse.data }));
    yield put(dashboardActions.SetContentLoading({ contentLoading: false }));
  });

  // Get all tickets
  yield takeEvery(dashboardActions.GetAllTickets, function* (action) {
    yield put(dashboardActions.SetContentLoading({ contentLoading: true }));
    const ticketResponse: AxiosResponse<Array<DashboardTicket>> =
      yield getAllTickets();
    yield put(dashboardActions.SetAllTickets({ tickets: ticketResponse.data }));
    yield put(dashboardActions.SetContentLoading({ contentLoading: false }));
  });

  // Get Recent Ticket Sales
  yield takeEvery(dashboardActions.GetTicketSales, function* (action) {
    yield put(
      dashboardActions.SetTicketSalesLoading({ ticketSalesLoading: true })
    );
    try {
      const recentTicketsResponse: AxiosResponse<Array<DashboardTicket>> =
        yield getTicketSales();
      yield put(
        dashboardActions.SetTicketSales({ tickets: recentTicketsResponse.data })
      );
      yield put(
        dashboardActions.SetTicketSalesLoading({ ticketSalesLoading: false })
      );
    } catch {}
  });

  // Get Recent Orders
  yield takeEvery(dashboardActions.GetRecentOrders, function* (action) {
    yield put(
      dashboardActions.SetRecentOrdersLoading({ recentOrdersLoading: true })
    );
    try {
      const recentOrdersResponse: AxiosResponse<Array<RecentOrder>> =
        yield getRecentOrders();
      yield put(
        dashboardActions.SetRecentOrders({ orders: recentOrdersResponse.data })
      );
      yield put(
        dashboardActions.SetRecentOrdersLoading({ recentOrdersLoading: false })
      );
    } catch {}
  });

  // Get Statistics
  yield takeEvery(dashboardActions.GetStatistics, function* (action) {
    try {
      const eventId = JSON.parse(localStorage.getItem("event") || "{}").id;
      const statisticsResponse: AxiosResponse<Statistics> = yield getStatistics(
        eventId
      );
      // This was the issue with blanking stats. we only set it if we have data
      if (statisticsResponse.data) {
        yield put(
          dashboardActions.SetStatistics({
            statistics: statisticsResponse.data,
          })
        );
      }
    } catch {}
    yield put(dashboardActions.SetContentLoading({ contentLoading: false }));
  });

  // Get all Tickets for Orders
  yield takeEvery(dashboardActions.GetOrders, function* (action) {
    yield put(dashboardActions.SetContentLoading({ contentLoading: true }));
    const orderResponse: AxiosResponse<PaginatedResponse<DashboardOrder>> =
      yield getOrders(action.payload.page);
    yield put(dashboardActions.SetOrders({ orders: orderResponse.data }));
    yield put(dashboardActions.SetContentLoading({ contentLoading: false }));
  });

  // Get Single Order
  yield takeEvery(dashboardActions.GetOrder, function* (action) {
    yield put(dashboardActions.SetContentLoading({ contentLoading: true }));
    try {
      const orderResponse: AxiosResponse<DashboardOrder> = yield getOrder(
        action.payload.orderId
      );
      yield put(dashboardActions.SetOrder({ order: orderResponse.data }));
      yield put(dashboardActions.SetContentLoading({ contentLoading: false }));
    } catch (error) {
      history.push("/dashboard/orders");
    }
  });

  // Sign In
  yield takeEvery(dashboardActions.SignIn, function* (action) {
    yield put(dashboardActions.SetIsSigningIn({ isSigningIn: true }));
    try {
      const signInResponse: AxiosResponse<SignInResponse> = yield signIn(
        action.payload.signInRequest
      );
      const decodedToken = decodeToken<any>(signInResponse.data.access);
      localStorage.setItem(ACCESS_TOKEN_KEY, signInResponse.data.access);
      localStorage.setItem(REFRESH_TOKEN_KEY, signInResponse.data.refresh);
      localStorage.setItem(EMAIL_KEY, decodedToken.email);
      localStorage.setItem(USER_ID_KEY, decodedToken.user_id);
      history.push("/dashboard");
    } catch {
      toast.error(`Invalid Email or Password`, {
        icon: "❌",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
    yield put(dashboardActions.SetIsSigningIn({ isSigningIn: false }));
  });

  // Get Events
  yield takeEvery(dashboardActions.GetEvents, function* (action) {
    yield put(dashboardActions.SetContentLoading({ contentLoading: true }));
    const eventsResponse: AxiosResponse<Array<Event>> = yield getEvents();
    // Only set events if we have it
    if (eventsResponse.data && eventsResponse.data.length > 0) {
      yield put(dashboardActions.SetEvents({ events: eventsResponse.data }));
      yield put(dashboardActions.SetContentLoading({ contentLoading: false }));
    }
  });

  // Resend Order Confirmation
  yield takeEvery(dashboardActions.ResendOrderConfirmtion, function* (action) {
    yield resendOrderConfirmation(action.payload.orderUUID);
    toast.success(`Order Confirmation Resent`, {
      icon: "📨",
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
  });

  // Download QR Codes for an Order
  yield takeEvery(dashboardActions.DownloadOrderQRCodes, function* (action) {
    const response: AxiosResponse<any> = yield downloadOrderQRCodes(
      action.payload.orderUUID
    );

    FileSaver.saveAs(response.data, `${action.payload.orderUUID}-qrcodes.zip`);
  });

  // Create Event
  yield takeEvery(dashboardActions.CreateEvent, function* (action) {
    const response: AxiosResponse<any> = yield createEvent(
      action.payload.event
    );

    yield put(
      dashboardActions.SetCreateEventSuccess({ createEventSuccess: true })
    );
  });

  // Get Organisation
  yield takeEvery(dashboardActions.GetOrganisation, function* (action) {
    const response: AxiosResponse<Organisation> = yield getOrganisation();
    yield put(
      dashboardActions.SetOrganisation({ organisation: response.data })
    );
  });

  // Get me for user page
  yield takeEvery(dashboardActions.GetMe, function* (action) {
    yield put(dashboardActions.SetContentLoading({ contentLoading: true }));
    const response: AxiosResponse<User> = yield getMe();
    yield put(dashboardActions.SetMe({ me: response.data }));
    yield put(dashboardActions.SetContentLoading({ contentLoading: false }));
  });

  // Update Me for user page
  yield takeEvery(dashboardActions.UpdateMe, function* (action) {
    yield put(dashboardActions.SetContentLoading({ contentLoading: true }));
    const response: AxiosResponse<User> = yield updateMe(action.payload.me);
    yield put(dashboardActions.SetMe({ me: response.data }));
    yield put(dashboardActions.SetContentLoading({ contentLoading: false }));
  });

  // Update password using the me endpoint
  yield takeEvery(dashboardActions.UpdatePassword, function* (action) {
    yield put(dashboardActions.SetContentLoading({ contentLoading: true }));
    const response: AxiosResponse<User> = yield updatePassword(
      action.payload.updatePasswordRequest
    );
    yield put(dashboardActions.SetMe({ me: response.data }));
    yield put(dashboardActions.SetContentLoading({ contentLoading: false }));
  });

  // Get Order Emails
  yield takeEvery(dashboardActions.GetOrderEmails, function* (action) {
    const response: AxiosResponse<Array<EmailMessage>> = yield getOrderEmails(
      action.payload.recipient
    );
    yield put(dashboardActions.SetOrderEmails({ orderEmails: response.data }));
  });
}

export function* dashboardSaga() {
  yield fork(dashboardSideEffects);
}
