import React, { useState, useEffect, Fragment } from "react";
import {
  Appearance,
  loadStripe,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { useSelector } from "react-redux";
import { State } from "../store/store";
import { PaymentDetailsForm } from "../forms/PaymentDetailsForm";
import { Transition } from "@headlessui/react";
import { PaymentPlanSchedule } from "./PaymentPlanSchedule";
import { Order, OrderResponse } from "../models/Order";

const REACT_APP_STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "";

const REACT_APP_STRIPE_CONNECTED_ACCOUNT_ID =
  process.env.REACT_APP_STRIPE_CONNECTED_ACCOUNT_ID || "";

export const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY, {
  stripeAccount: REACT_APP_STRIPE_CONNECTED_ACCOUNT_ID,
});

interface Props {
  order: OrderResponse;
  redirectUrl?: string;
}

export const PaymentDetails = ({ order, redirectUrl }: Props) => {
  const billingDetailsSubmitting = useSelector(
    (state: State) => state.cart.billingDetailsSubmitting
  );

  const appearance: Appearance = {
    theme: "stripe",
  };
  const options: StripeElementsOptions = {
    clientSecret: order?.clientSecret,
    appearance,
  };

  return (
    <div className={order ? "lg:mt-8 -mt-3 px-1 lg:px-0" : ""}>
      <Transition
        as={Fragment}
        show={!!order}
        enter="transform transition duration-[400ms]"
        enterFrom="opacity-0 scale-50"
        enterTo="opacity-100 scale-100"
        leave="transform duration-200 transition ease-in-out"
        leaveFrom="opacity-100 scale-100 "
        leaveTo="opacity-0 scale-95 "
      >
        <div className="lg:pl-0.5">
          <div
            className={order ? "lg:border-t lg:border-gray-200 lg:pt-3" : ""}
          ></div>
          {order?.monthlyTotal && (
            <PaymentPlanSchedule order={order} showPaymentTerms={true} />
          )}
          {order?.clientSecret && (
            <Elements options={options} stripe={stripePromise}>
              <PaymentDetailsForm order={order} redirectUrl={redirectUrl} />
            </Elements>
          )}
        </div>
      </Transition>
      {!order && billingDetailsSubmitting && (
        <div className="flex items-center justify-center w-full h-64">
          <svg
            className="animate-spin h-12 w-12 text-blue-200"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      )}
    </div>
  );
};
