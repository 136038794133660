import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Fragment, useEffect, useState } from "react";
import { classNames } from "../../utils/styles";
import { DropdownOption } from "../../models/Dashboard";
import { getIn, useField, useFormikContext } from "formik";

interface Props {
  name: string;
  options: Array<DropdownOption>;
  multiple?: boolean;
  isUpdating?: boolean;
}

export const Dropdown = ({ name, options, multiple, isUpdating }: Props) => {
  const { setFieldValue, values, errors } = useFormikContext();
  const [field] = useField(name);
  // Initialize state based on multiple prop
  const [selected, setSelected] = useState<DropdownOption | DropdownOption[]>(
    multiple ? [] : options[0]
  );
  const [haveSetValues, setHaveSetValues] = useState(false);
  const [error, setError] = useState<any>("");

  useEffect(() => {
    if (!selected) {
      setHaveSetValues(false);
    }
  }, [options]);

  useEffect(() => {
    setError(getIn(errors, name) || "");
  }, [errors]);

  // Set initial values
  useEffect(() => {
    // only apply pre populating values if we are updating, not creating
    if (isUpdating) {
      if (!haveSetValues) {
        if (multiple && field.value && field.value.length > 0) {
          // check if the selected options [1, 2, 4] are in the options
          // also checking if there are in in the format {"id": "", "value": ""}
          const foundOptions = options.filter((option) => {
            if (Array.isArray(field.value) && field.value.length > 0) {
              if (typeof field.value[0] === "number") {
                // field.value is a list of integers
                return field.value.includes(option.id);
              } else if (
                typeof field.value[0] === "object" &&
                "id" in field.value[0]
              ) {
                // field.value is a list of objects with an id property
                return field.value.some((item) => item.id === option.id);
              }
            }
            return false;
          });
          if (foundOptions) {
            setSelected(foundOptions);
            setHaveSetValues(true);
          }
        } else {
          const option = options.find((opt) => opt.id === field.value);
          if (option) {
            setSelected(option);
            setHaveSetValues(true);
          }
        }
      }
    }
  }, [field.value, options]);

  useEffect(() => {
    setFieldValue(name, selected);
  }, [selected, name, setFieldValue, field.value]);

  const handleSelectionChange = (option: DropdownOption) => {
    if (multiple) {
      setSelected((currentSelected) => {
        const arraySelected = currentSelected as DropdownOption[];
        const selectedIndex = arraySelected.findIndex(
          (opt) => opt.id === option.id
        );
        if (selectedIndex > -1) {
          // Already selected, remove it
          return arraySelected.filter((opt) => opt.id !== option.id);
        } else {
          // Not selected, add it
          return [...arraySelected, option];
        }
      });
    } else {
      // Single select, just set the new option
      setSelected(option);
    }
  };

  const getDisplayValue = () => {
    if (multiple) {
      // If multiple, join the selected option values
      const name = (selected as DropdownOption[])
        .map((opt) => opt.value) // Get the value of each selected option
        .join(", "); // Join with comma
      return name ? name : "Nothing selected";
    } else {
      // If single, just return the selected option value
      return (selected as DropdownOption).value;
    }
  };

  return (
    <div className="z-50">
      <Listbox
        value={
          multiple
            ? (selected as DropdownOption[]).map((opt) => opt.id)
            : (selected as DropdownOption).id
        }
        onChange={(ids: any) => {
          // If multiple is true, 'ids' will be an array
          if (multiple) {
            // Handle multiple ids here
            // Find the options for the ids and update the selection
            const selectedOptions = options.filter((opt) =>
              ids.includes(opt.id)
            );
            setSelected(selectedOptions);
          } else {
            // Handle a single id here (for single select dropdown)
            const option = options.find((opt) => opt.id === ids);
            if (option) {
              handleSelectionChange(option);
            }
          }
        }}
        multiple={multiple}
      >
        {({ open }) => (
          <>
            <div className="relative">
              <Listbox.Button
                className={`w-full relative cursor-default rounded-md bg-white dark:bg-dark-secondary dark:text-gray-400 py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                  error ? "ring-red-500" : "dark:ring-white/5 ring-gray-300 "
                }`}
              >
                <span className="block truncate">{getDisplayValue()}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-dark-secondary dark:ring-white/5 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map((option: DropdownOption) => (
                    <Listbox.Option
                      key={option.id}
                      className={({ active }) =>
                        classNames(
                          active
                            ? "bg-indigo-600 text-white "
                            : "text-gray-900 dark:text-gray-400",
                          "relative cursor-pointer select-none py-2 pl-3 pr-9 "
                        )
                      }
                      value={option.id}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {option.value}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-indigo-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {error && (
        <div className="mt-1">
          <span className="text-red-500 text-xs">{error}</span>
        </div>
      )}
    </div>
  );
};
