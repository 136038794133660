import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../store/store";
import { dashboardActions } from "../../../store/dashboard";
import { Fragment, useRef } from "react";
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import { CreateEventForm } from "./CreateEventForm";
import { Formik } from "formik";
import yup from "../../../crud/yup-extended";
import { Event } from "../../../models/Dashboard";
import successAnimation from "../../../assets/successAnimation.json";
import Lottie from "lottie-react";

export const CreateEventModal = () => {
  const dispatch = useDispatch();
  const createEventModalOpen = useSelector(
    (state: State) => state.dashboard.createEventModalOpen
  );
  const createEventSuccess = useSelector(
    (state: State) => state.dashboard.createEventSuccess
  );

  const setIsOpen = (isOpen: boolean) => {
    dispatch(
      dashboardActions.SetCreateEventModalOpen({ createEventModalOpen: isOpen })
    );
  };

  return (
    <Transition.Root show={createEventModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 dark:bg-black dark:bg-opacity-75 blur bg-opacity-75 transition-opacity backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg border dark:border-white/5 bg-light-primary dark:bg-dark-primary text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                {createEventSuccess ? (
                  <div className="flex py-8 pl-2 pr-12">
                    <div className="w-56 mx-4">
                      <Lottie animationData={successAnimation} loop={false} />
                    </div>
                    <div>
                      <h1 className="text-gray-900 dark:text-white text-3xl font-semibold">
                        Submitted Event for Creation
                      </h1>
                      <p className="pt-2 pb-2 text-gray-500 dark:text-gray-400 font-light">
                        To make sure Ticketr events are high quality and are
                        verified as being run by the organisers, we review all
                        events before they go live. Your event is currently in
                        draft mode and you can still start adding tickets and
                        setting up your event.
                        <br />
                        <br />
                        We also like to make sure that we offer the best
                        price/fee to our organisers, so we will be in touch
                        before the event goes live. Please let your account
                        manager know you have submitted an event for review.
                      </p>

                      <button
                        onClick={() =>
                          dispatch(
                            dashboardActions.SetCreateEventModalOpen({
                              createEventModalOpen: false,
                            })
                          )
                        }
                        type="button"
                        className="mt-4 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Okay, got it
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="bg-light-primary dark:bg-dark-secondary py-3 sm:flex sm:px-5 text-gray-900 dark:text-white font-medium">
                      Create Event
                    </div>
                    <Formik
                      enableReinitialize
                      initialValues={{}}
                      validationSchema={yup.object({
                        name: yup.string().required("Required"),
                        location: yup.string().required("Required"),
                        startDate: yup.date().required("Required"),
                        endDate: yup
                          .date()
                          .required("Required")
                          .min(
                            yup.ref("startDate"),
                            "End date can't be before start date"
                          ),
                        description: yup.string().required("Required"),
                      })}
                      onSubmit={(values, { resetForm }) => {
                        dispatch(
                          dashboardActions.CreateEvent({
                            event: values as Event,
                          })
                        );
                      }}
                      validateOnMount={false}
                      validateOnBlur={false}
                      validateOnChange={false}
                    >
                      {({
                        errors,
                        handleSubmit,
                        values,
                        setFieldValue,
                        initialValues,
                      }) => (
                        <>
                          <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                            <div className="rounded-md bg-light-primary dark:bg-dark-secondary p-4 mb-4">
                              <div className="flex">
                                <div className="flex-shrink-0">
                                  <InformationCircleIcon
                                    className="h-5 w-5 text-gray-900 dark:text-white"
                                    aria-hidden="true"
                                  />
                                </div>
                                <div className="ml-3 flex-1 md:flex md:justify-between">
                                  <p className="text-sm text-gray-900 dark:text-white">
                                    Events that are submitted will be in "draft
                                    mode" and reviewed before they are live.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <CreateEventForm />
                          </div>
                          <div className="bg-light-primary dark:bg-dark-secondary py-3 sm:flex sm:flex-row-reverse sm:px-3">
                            <button
                              type="button"
                              className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                              onClick={() => handleSubmit()}
                            >
                              Submit
                            </button>
                            <button
                              type="button"
                              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                              onClick={() => setIsOpen(false)}
                            >
                              Cancel
                            </button>
                          </div>
                        </>
                      )}
                    </Formik>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
