import { Navigate, useNavigate } from "react-router-dom";
import { isExpired, decodeToken } from "react-jwt";
import axios from "axios";
import { refreshAccessToken } from "../dashboardQueries";
import { history } from "..";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../store/store";
import { dashboardActions } from "../store/dashboard";
import { useEffect } from "react";
import { Event } from "../models/Dashboard";

interface Props {
  children: any;
}

export const ACCESS_TOKEN_KEY = "auth:access";
export const REFRESH_TOKEN_KEY = "auth:refresh";
export const EMAIL_KEY = "auth:email";
export const USER_ID_KEY = "auth:userId";
export const EVENT_KEY = "event";

export const signOut = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(EMAIL_KEY);
  localStorage.removeItem(USER_ID_KEY);
  localStorage.removeItem(EVENT_KEY);
  history.push("/sign-in");
  window.location.reload();
};

export const ProtectedRoute = ({ children }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const events: Array<Event> = useSelector(
    (state: State) => state.dashboard.events
  );

  // Set event and set axios header
  const setActiveEvent = (activeEvent: Event) => {
    localStorage.setItem(EVENT_KEY, JSON.stringify(activeEvent));
    // window.location.reload();
  };

  useEffect(() => {
    dispatch(dashboardActions.GetEvents());
    dispatch(dashboardActions.GetOrganisation());
  }, []);

  useEffect(() => {
    if (events && events.length > 0 && !localStorage.getItem("event")) {
      setActiveEvent(events[0]);
    }
  }, [events]);

  const isAuthenticated = (): boolean => {
    // check if the user is Authenticated by:
    // 3. Try to refresh it and if that fails then no good
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

    // 1. Checking for a token in storage
    if (!accessToken || !refreshToken) {
      return false;
    }

    // 2. Checking locally it's not expired
    const decodedToken = decodeToken(accessToken);

    if (!decodedToken) {
      return false;
    }
    // We don't want to do the below because if the access token is expired, we just want to try refresh
    // else {
    //   // Check if the token is expired
    //   if (isExpired(accessToken)) {
    //     // Try and refresh
    //     return false;
    //   }
    // }

    // Set the axios request interceptor for all requests
    const requestInterceptor = axios.interceptors.request.use((config) => {
      const innerAccessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
      const event = JSON.parse(localStorage.getItem(EVENT_KEY) || "{}");
      if (accessToken) {
        config.headers.Authorization = `Bearer ${innerAccessToken}`;
        // Add context for what Event it is
        config.headers.EventID = event.id;
      }
      return config;
    });

    // Set response interceptor
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        // if theres an error and it's a 401
        if (error.response && error.response.status === 401) {
          // A 401 response indicates an unauthorized request. Attempt to refresh the token only if the request was to an endpoint that isn't /refresh
          if (error.response.request.responseURL.includes("/token/refresh")) {
            signOut();
            return Promise.reject(error);
          } else if (refreshToken) {
            try {
              // Try refresh by calling API
              const response = await refreshAccessToken(refreshToken);
              const accessToken = response.data.access;

              // Clear the statistics intervalId if it's there and set that we have refreshed the token
              // if (statisticsIntervalId) {
              //   console.log(
              //     `There is a statistics interval id set ${statisticsIntervalId}, clearing it`
              //   );
              //   clearInterval(statisticsIntervalId);
              //   dispatch(
              //     dashboardActions.SetHasTokenRefereshed({
              //       hasTokenRefreshed: true,
              //     })
              //   );
              //   console.log(
              //     "Cleared the statistics interval id and set that we have refreshed the token"
              //   );
              // }

              // If we got a new access token
              if (accessToken) {
                // Update the token in storage and retry the request
                localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);

                // Remove the old request interceptor which has the old, original access token.
                // When the next request is made a new interceptor will be made with the new access token.
                axios.interceptors.request.eject(requestInterceptor);

                // Clone the original request and update the Authorization header
                const originalRequest = error.config;

                // Set the new header. Although, since we set the local storage and ejected the header, I don't think this is neccessary, but a good step nonetheless
                originalRequest.headers.Authorization = `Bearer ${accessToken}`;

                // retry the request
                return axios(originalRequest);
                // return true;
              }
            } catch (refreshError) {
              console.error("Failed to refresh token", refreshError);
            }
          }

          // If token refresh fails or refreshToken doesn't exist, navigate to the sign-in page.
          return false;
          // navigate("/sign-in");
          // return <Navigate to="/sign-in" />;
        }
        return Promise.reject(error);
      }
    );

    return true;
  };
  if (!isAuthenticated()) {
    return signOut();
  }
  return children;
};
